import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

import Image from '../Image'
import Link from '../Link'

import './styles.scss'

const stringifyTags = tags => {
  const tagsStr = []
  tags.forEach(t => {
    tagsStr.push(`#${t}`)
  })
  return tagsStr.join(' · ')
}

const Card = ({ fileName, title, description, tags, links, githubInfo }) => {
  const [githubStars, setGithubStars] = useState(null)

  useEffect(() => {
    if (githubInfo) {
      fetchGitHubStars()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchGitHubStars = async () => {
    try {
      const res = await fetch(
        `https://api.github.com/repos/${githubInfo.name}/${githubInfo.repo}`
      )

      const resData = await res.json()
      setGithubStars(resData.watchers)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="portfolio-card">
      <div className="img-wrap">
        <Image
          fileName={fileName}
          style={{ maxWidth: '100%', maxHeight: '250px', objectFit: 'cover' }}
          alt={`img-${fileName}`}
        />
      </div>
      <div className="portfolio-texts">
        <h1 className="project-title">
          {title}
          {githubStars && (
            <span className="github-star">
              <FontAwesomeIcon
                className="icon-far-star"
                icon={farStar}
                size="xs"
              />
              {`${githubStars}`}
            </span>
          )}
        </h1>
        <p className="project-description">{description}</p>
        <p className="project-tags">{stringifyTags(tags)}</p>
        <div className="portfolio-links">
          {links &&
            links.map((link, i) => (
              <Link
                key={i}
                link={link.link}
                label={link.label}
                linkStyle={link.linkStyle}
                disabled={link.disabled}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default Card
