import React, { useEffect, useRef } from 'react'
import { ThemeConsumer } from 'styled-components'
import Layout from '../../components/Layout'
import { Card } from '../../components/Portfolio'
import { SITE_LINKS } from '../../constants'

import './styles.scss'

const projects = [
  {
    title: 'Ramen Kid 🍜',
    fileName: 'rk.png',
    desc:
      'Website for a local business where the client can modify most of the contents embedded on the webpage and manage subscriptions. Had a full ownership of the website from design decisions to building REST APIs',
    tags: [
      'React',
      'Node.js',
      'Express.js',
      'MongoDB',
      'AWS',
      'EC2',
      'Restaurant',
    ],
    links: [
      { label: 'Website', link: 'https://ramenkid.com', linkStyle: 'site' },
      {
        label: 'Features',
        link: `${SITE_LINKS.mainLink}/portfolio/ramen-kid`,
        linkStyle: 'feature',
      },
    ],
  },
  {
    title: 'Game Code Share 🕹',
    fileName: 'game-code-share.png',
    desc:
      'Provides convenient web interface where users can easily share their game codes and chat with other players. Took advantage of server side rendering with Next.js and socket.io for implementing chat feature',
    tags: ['Next.js', 'socket.io', 'React', 'Node.js', 'Express.js', 'MongoDB'],
    links: [
      {
        label: 'Website',
        link: 'https://gamecodeshare.com/',
        linkStyle: 'site',
      },
      // {
      //   label: 'Features',
      //   link: `${SITE_LINKS.mainLink}/portfolio/game-code-share`,
      //   disabled: true,
      //   linkStyle: 'feature',
      // },
    ],
  },
  {
    title: 'gatsby-blog-mdx',
    fileName: 'gatsby-blog-mdx.png',
    desc:
      'Open source blog starter pack that allows users to creat blog posts using markdown or React components. Highly focused on building simple & user friendly design and performance',
    tags: ['Gatsby.js', 'GraphQL', 'React'],
    githubInfo: { name: 'EllisMin', repo: 'gatsby-blog-mdx' },
    links: [
      {
        label: 'Demo Site',
        link: 'https://gatsby-blog-mdx-demo.pages.dev/',
        linkStyle: 'site',
      },
      {
        label: 'Source',
        link: 'https://github.com/EllisMin/gatsby-blog-mdx',
        linkStyle: 'github',
      },
      {
        label: 'Features',
        link: 'https://gatsby-blog-mdx-demo.pages.dev/2020/05/1-features/',
        linkStyle: 'feature',
      },
    ],
  },
]

const setLightTheme = theme => {
  theme.setTheme({ mode: 'light' })
}

const PortfolioPage = () => {
  useEffect(() => {
    setThemeRef.current.click()
  }, [])

  const setThemeRef = useRef(null)

  return (
    <Layout showTitle={true}>
      <ThemeConsumer>
        {theme => {
          return (
            <div className="portfolio">
              {/* Hidden div to force light theme */}
              <div
                className="set-theme"
                style={{ display: 'none' }}
                onClick={() => setLightTheme(theme)}
                ref={setThemeRef}
                onKeyPress={() => {}}
                role={'none'}
              />
              <h1 className="portfolio-title">Projects_</h1>
              <div className="portfolio-cards">
                {projects.map((project, i) => (
                  <Card
                    key={i}
                    fileName={project.fileName}
                    title={project.title}
                    description={project.desc}
                    tags={project.tags}
                    links={project.links}
                    githubInfo={project.githubInfo}
                  />
                ))}
              </div>
            </div>
          )
        }}
      </ThemeConsumer>
    </Layout>
  )
}

export default PortfolioPage
